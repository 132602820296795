<template>
    <data-table
        id="owner-accounts-list"
        :columns="visible_columns"
        :rows="filtered_rows"
        :search="state.search"
        :total="state.total"
        :found="state.found"
        :current-page="state.page"
        :total-pages="nb_pages"
        :can-add="false"
        :searchable="false"
        :clickable="false"
        :compact="small_screen"
        @sort="sort_by"
        @page="goto_page"
    >
        <template
            v-slot:summary="{ row }"
        >
            <h4>{{ constant("account_statii", row.computed_status).name }}&nbsp;&bull;&nbsp;{{ row.amount | nibnut.currency }}</h4>
            <h5 class="text-gray">{{ translate('Account #{number}', { number: row.number }) }}</h5>
            <h6>{{ row.computed_owner_names }}</h6>
            <h6>{{ row.address_one_liner }}</h6>
            <div class="mt-2">
                {{ translate("Property Type") }}:&nbsp;
                <defined-term
                    :definition="property_type_definition(row.property_type_id)"
                >
                    {{ row.computed_property_type_code }}
                </defined-term>
            </div>
            <!--
            <div class="mt-2">
                {{ translate("Relationship To Owner") }}:&nbsp;
                <defined-term
                    :definition="owner_relationship_for_row(row).definition || ''"
                >
                    {{ owner_relationship_for_row(row).code }}
                </defined-term>
            </div>
            //-->
            <div class="mt-2">
                {{ translate("Reported Date") }}:&nbsp;{{ row.reported_on | nibnut.date }}
            </div>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <span
                v-if="field === 'computed_status'"
            >
                {{ constant("account_statii", row.computed_status).name }}
            </span>
            <div v-else-if="field === 'number'">
                {{ row.number }}
                <div class="text-small text-gray">{{ row.computed_holder_name }}</div>
            </div>
            <span v-else-if="field === 'amount'">{{ row.amount | nibnut.currency }}</span>
            <defined-term
                v-else-if="field === 'computed_property_type_code'"
                :definition="property_type_definition(row.property_type_id)"
            >
                {{ row.computed_property_type_code }}
            </defined-term>
            <!--
            <defined-term
                v-else-if="field === 'owner_relationship'"
                :definition="owner_relationship_for_row(row).definition || ''"
            >
                {{ owner_relationship_for_row(row).code }}
            </defined-term>
            //-->
            <span v-else-if="field === 'reported_on'">{{ row.reported_on | nibnut.date }}</span>
            <span v-else>{{ row[field] }}</span>
        </template>
        <template v-slot:footer>
            <tfoot class="text-bold">
                <tr v-if="small_screen">
                    <td class="text-right">
                        {{ translate("Total") }}:&nbsp;
                        {{ total_amount | nibnut.currency }}
                    </td>
                </tr>
                <tr v-else>
                    <td colspan="4" class="text-right">
                        {{ translate("Total") }}
                    </td>
                    <td class="text-right">
                        {{ total_amount | nibnut.currency }}
                    </td>
                    <td colspan="3">
                        &nbsp;
                    </td>
                </tr>
            </tfoot>
        </template>
    </data-table>
</template>

<script>
import { mapGetters } from "vuex"

import { is_remote_data_table_source } from "@/nibnut/mixins"

import { DefinedTerm } from "@/custom/components"

export default {
    name: "Accounts",
    mixins: [is_remote_data_table_source],
    components: {
        DefinedTerm
    },
    watch: {
        ownerId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_value("owner_id", this.ownerId)
        },
        property_type_definition (property_type_id) {
            return (this.entity_record("property_type", property_type_id) || {}).name
        },
        owner_relationship_for_row (row) {
            const account_owner = this.entity_records("account_owner").find(account_owner => {
                return (account_owner.owner_id === this.ownerId) && (account_owner.account_id === row.id)
            })
            if(account_owner) {
                const owner_relationship = this.entity_record("owner_relationship", account_owner.owner_relationship_id)
                if(owner_relationship) return owner_relationship
            }
            return {
                code: "",
                definition: ""
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        small_screen () {
            return !!this.$mq.match(/^(xs|sm|md|lg|tl)$/i)
        },
        can_refresh () {
            return this.profile_id && this.ownerId
        },
        fields () {
            return ["fieldset::owner-list"]
        },
        total_amount () {
            return this.filtered_rows.reduce((total, value) => total + value.amount, 0)
        }
    },
    props: {
        ownerId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "account",
            columns: {
                computed_status: { label: "Status", sort: false },
                number: { label: "Account #", sort: null, type: "alpha" },
                computed_owner_names: { label: "Reported Names", sort: false, type: "alpha" },
                address_one_liner: { label: "Reported Address", sort: false },
                amount: { label: "Amount", sort: "desc", type: "amount", head_class: "text-right", cell_class: "text-right" },
                computed_property_type_code: { label: "Property Type", sort: null, type: "alpha", head_class: "text-center", cell_class: "text-center" },
                // owner_relationship: { label: "Relationship To Owner", sort: false },
                reported_on: { label: "Reported Date", sort: null, type: "numeric" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "amount",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                owner_id: 0
            }
        }
    }
}
</script>
