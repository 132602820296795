<template>
    <page
        id="owner-editor"
        :title="page_title"
        :back-navigation-fallback="default_back"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <template v-slot:title>
            <div class="owner-aliases">
                <h2>
                    {{ page_title }}
                </h2>
                <!--
                <div
                    v-if="!!aliases.length"
                >
                    <span
                        v-for="alias in aliases"
                        :key="alias.id"
                        class="chip"
                    >
                        {{ alias.name }}
                    </span>
                </div>
                <div v-if="!!edited_record && !!edited_record.id">
                    <default-button
                        color="primary"
                        :title="translate('Enrich data')"
                        :block="small_screen"
                        @click.prevent="enrich"
                    >
                        <open-icon glyph="file-import" />
                        <span v-if="!$mq.match(/^(md|lg|tl)$/i)" class="ml-2">{{ translate('Enrich data') }}</span>
                    </default-button>
                </div>
                //-->
            </div>
        </template>

        <div class="columns">
            <div class="column col-3 col-md-4 col-sm-12">
                <aside v-if="!!edited_record" class="internal-fields sticker">
                    <div class="title-proxy">
                        {{ page_title }}
                    </div>
                    <form-dropdown
                        id="status"
                        name="status"
                        v-model="edited_record.status"
                        :options="statii"
                        :required="true"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Status") }}</template>
                    </form-dropdown>
                    <form-toggle-input
                        id="personal_profile"
                        type="checkbox"
                        name="personal_profile"
                        v-model="edited_record.personal_profile"
                        class="mb-0"
                        @input="save"
                    >
                        {{ translate("Personal Profile Found") }}
                    </form-toggle-input>
                    <form-toggle-input
                        id="state_records_requested_on_flag"
                        type="checkbox"
                        name="state_records_requested_on_flag"
                        :value="!!edited_record.state_records_requested_on"
                        class="mt-4 mb-0"
                        @input="save_date($event ? $dayjs() : null, 'state_records_requested_on')"
                    >
                        {{ translate("State Records Requested") }}
                    </form-toggle-input>
                    <div v-if="!!edited_record.state_records_requested_on" class="columns">
                        <div class="column">
                            <form-date-input
                                id="state_records_requested_on"
                                name="state_records_requested_on"
                                v-model="edited_record.state_records_requested_on"
                                position="right"
                                :required="false"
                                @input="save_date"
                            >
                                <template v-slot:label>{{ translate("Date") }}</template>
                            </form-date-input>
                        </div>
                        <div v-if="!!edited_record.state_records_requested_on" class="column">
                            <form-input
                                id="state_records_claim_number"
                                name="state_records_claim_number"
                                v-model="edited_record.state_records_claim_number"
                                :required="false"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Claim #") }}</template>
                            </form-input>
                        </div>
                    </div>
                    <form-toggle-input
                        id="obituary_found"
                        type="checkbox"
                        name="obituary_found"
                        v-model="edited_record.obituary_found"
                        class="mt-4 mb-0"
                        @input="save"
                    >
                        {{ translate("Obituary Found") }}
                    </form-toggle-input>
                    <form-input
                        v-if="edited_record.obituary_found"
                        id="obituary_url"
                        name="obituary_url"
                        v-model="edited_record.obituary_url"
                        :required="false"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Url") }}</template>
                    </form-input>

                    <form-textbox
                        id="contact_notes"
                        :data-version="edited_record.id"
                        name="contact_notes"
                        v-model="edited_record.contact_notes"
                        size="lg"
                        :required="false"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Contacts") }}</template>
                    </form-textbox>
                    <form-textbox
                        id="general_notes"
                        :data-version="edited_record.id"
                        name="general_notes"
                        v-model="edited_record.general_notes"
                        size="lg"
                        :required="false"
                        @input="save"
                    >
                        <template v-slot:label>{{ translate("Notes") }}</template>
                    </form-textbox>
                </aside>
            </div>
            <div class="column col-9 col-md-8 col-sm-12">
                <!--
                <div class="columns">
                    <div class="column col-7 col-md-12 col-sm-12">
                        <section>
                            <div class="columns">
                                <div class="column col-4 col-md-4 col-sm-12">
                                    <form-input
                                        id="ssn"
                                        name="ssn"
                                        value="482-46-7224"
                                        :required="false"
                                        :editable="false"
                                    >
                                        <template v-slot:read_only>
                                            <label
                                                class="form-label"
                                            >
                                                {{ translate("SSN") }}
                                            </label>
                                            <label
                                                class="form-label disabled-field"
                                            >
                                                {{ "482-46-7224" }}
                                            </label>
                                            <div class="text-small">
                                                {{ translate("Issued in {state}, {year}", { state: "IA", year: 1956 }) }}
                                            </div>
                                        </template>
                                    </form-input>
                                </div>
                                <div class="column col-4 col-md-4 col-sm-12">
                                    <form-input
                                        id="dob"
                                        name="dob"
                                        value="01/01/1941"
                                        :required="false"
                                        :editable="false"
                                    >
                                        <template v-slot:label>
                                            {{ translate("DOB") }}
                                        </template>
                                        <template v-slot:empty-value>
                                            {{ "01/01/1941" || nibnut.date }} ({{ "69" }})
                                        </template>
                                    </form-input>
                                </div>
                                <div v-if="true" class="column col-4 col-md-4 col-sm-12">
                                    <form-input
                                        id="dod"
                                        name="dod"
                                        value="01/01/2010"
                                        :required="false"
                                        :editable="false"
                                    >
                                        <template v-slot:label>
                                            {{ translate("DOD") }}
                                        </template>
                                    </form-input>
                                </div>
                            </div>
                        </section>

                        <section>
                            <h6>{{ translate("Email:::Emails", {}, emails.length) }}</h6>
                            <variations-display
                                :current-variation-id="2"
                                :variations="emails"
                                @toggle="save($event.id, 'current_email_id')"
                                @validate="toggle_validation('email', $event)"
                            >
                                <template v-slot:default="{ variation }">
                                    {{ variation.name }}
                                </template>
                            </variations-display>

                            <h6>{{ translate("Phone:::Phones", {}, phones.length) }}</h6>
                            <variations-display
                                :current-variation-id="2"
                                :variations="phones"
                                @toggle="save($event.id, 'current_phone_id')"
                                @validate="toggle_validation('phone_number', $event)"
                            >
                                <template v-slot:default="{ variation }">
                                    {{ variation.type }}: {{ variation.name }}
                                </template>
                            </variations-display>

                            <h6>{{ translate("Address:::Addresses", {}, addresses.length) }}</h6>
                            <variations-display
                                :current-variation-id="2"
                                :variations="addresses"
                                @toggle="save($event.id, 'current_adddress_id')"
                                @validate="toggle_validation('address_owner', $event)"
                            >
                                <template v-slot:default="{ variation }">
                                    {{ variation.name }}
                                    <div class="text-gray text-small">{{ variation.date_range }}</div>
                                </template>
                            </variations-display>

                            <h6>{{ translate("IP Address:::IP Addresses", {}, ip_addresses.length) }}</h6>
                            <variations-display
                                :current-variation-id="2"
                                :variations="ip_addresses"
                                @toggle="save($event.id, 'current_ip_address_id')"
                                @validate="toggle_validation('ip_address', $event)"
                            >
                                <template v-slot:default="{ variation }">
                                    {{ variation.name }}
                                </template>
                            </variations-display>
                        </section>
                    </div>
                    <div class="column col-5 col-md-12 col-sm-12">
                        <simple-relationship
                            title="Property:::Properties"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                        <simple-relationship
                            title="Motor Vehicle:::Motor Vehicles"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                        <simple-relationship
                            title="Aircraft:::Aircrafts"
                            :count="aircrafts.length"
                        >
                            {{aircrafts}}
                        </simple-relationship>
                        <simple-relationship
                            title="Lien:::Liens"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                        <simple-relationship
                            title="Foreclosure:::Foreclosures"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                        <simple-relationship
                            title="Bankruptcy:::Bankruptcies"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                        <simple-relationship
                            title="Judgement:::Judgements"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                        <simple-relationship
                            title="Criminal Record:::Criminal Records"
                            :count="properties.length"
                        >
                            {{properties}}
                        </simple-relationship>
                    </div>
                </div>
                //-->

                <section v-if="edited_record">
                    <h6>{{ translate("Account:::Accounts", {}, 2) }}</h6>
                    <accounts
                        :owner-id="edited_record.id"
                    />
                </section>

                <heirs
                    v-if="edited_record"
                    :owner-id="edited_record.id"
                />
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import StickyEvents from "sticky-events"

import { is_record_editor_page } from "@/nibnut/mixins"

import {
    FormInput,
    FormToggleInput,
    FormDateInput,
    FormDropdown,
    FormTextbox
    /*
    DefaultButton,
    OpenIcon
    */
} from "@/nibnut/components"
/*
import {
    VariationsDisplay
} from "@/custom/components"
import SimpleRelationship from "./SimpleRelationship"
*/
import Accounts from "./Accounts"
import Heirs from "./Heirs"

let sticky_event_handler
const stuck_handler = (event) => {
    event.target.classList.toggle("stuck", event.detail.isSticky)
}

export default {
    mixins: [is_record_editor_page],
    components: {
        FormInput,
        FormToggleInput,
        FormDateInput,
        FormDropdown,
        FormTextbox,
        /*
        DefaultButton,
        OpenIcon,
        VariationsDisplay,
        SimpleRelationship,
        */
        Accounts,
        Heirs
    },
    created () {
        sticky_event_handler = new StickyEvents({
            stickySelector: ".sticker"
        })
    },
    mounted () {
        this.maybe_load_list_values()

        if(!this.small_screen) {
            setTimeout(() => {
                const stickers = document.querySelectorAll(".sticker")
                if(stickers) {
                    stickers.forEach(sticker => {
                        sticker.addEventListener(StickyEvents.CHANGE, stuck_handler)
                    })
                    sticky_event_handler.addStickies(stickers)
                }
            }, 500)
        }
    },
    beforeUnload () {
        const { stickers } = sticky_event_handler
        if(stickers) {
            stickers.forEach(sticker => {
                sticker.removeEventListener(StickyEvents.CHANGE, stuck_handler)
            })
        }
    },
    watch: {
        profile_id: "maybe_load_list_values"
    },
    methods: {
        maybe_load_list_values () {
            if(this.profile_id) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "property_type"
                    }
                ).catch(error => {
                    console.error(error)
                })
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "owner_relationship"
                    }
                ).catch(error => {
                    console.error(error)
                })
            }
        },
        save_values (entity, record, data) {
            if(record) {
                Object.keys(data).forEach(field => {
                    if(record[field] !== data[field]) record[field] = data[field]
                })
                if(record.id) return this.save_data_for_record_id(entity, record.id, data)
            }
            return Promise.resolve()
        },
        toggle_validation (entity, validation) {
            if(validation.validated_at) {
                this.save_values(entity, validation, {
                    validated_at: null,
                    validated_by: 0
                })
            } else {
                this.save_values(entity, validation, {
                    validated_at: this.$dayjs().format("YYYY-MM-DD HH:mm:ss"),
                    validated_by: this.profile_id
                })
            }
        }
        /*
        enrich () {
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: this.entity,
                    action: "request-enrichment",
                    data: {
                        selected_row_ids: [this.edited_record.id]
                    },
                    passthru: true
                }
            ).then(response => {
                this.$success(response.success)
            }).catch(error => {
                this.$error(error.message)
            })
        }
        */
    },
    computed: {
        page_title () {
            const record = this.edited_record
            if(record && record.computed_name) return record.computed_name
            return this.translate("Owner")
        },
        default_back () {
            return { title: this.translate("Dashboard"), href: { name: "home" } }
        },
        reload_query () {
            return {
                relation_ids: this.relation_ids
            }
        },
        statii () {
            return Object.values(this.constants("owner_statii"))
        }
        /*
        aliases () {
            return [
                { id: 2, name: "Maria M Marin" },
                { id: 3, name: "Maria Magdalena Marin" }
            ]
        },
        emails () {
            return [
                { id: 2, name: "RSWANSON@WORLDNET.ATT.NET", last_seen: "" },
                { id: 3, name: "JANEWALTERS3@GMAIL.COM", last_seen: "09/03/2021" },
                { id: 4, name: "WALTERSJ@PALMBEACH.K12.FL.US", last_seen: "08/11/2011" }
            ]
        },
        phones () {
            return [
                { id: 2, type: "C", name: "561-350-1825", last_seen: "09/03/2021" },
                { id: 3, type: "R", name: "561-482-5031", last_seen: "05/01/2021" },
                { id: 4, type: "R", name: "561-482-5831", last_seen: "10/28/1997" }
            ]
        },
        ip_addresses () {
            return [
                { id: 2, name: "127.0.0.1", last_seen: "09/03/2021" }
            ]
        },
        addresses () {
            return [
                { id: 2, name: "7881 REFLECTION COVE DR APT 208, FORT MYERS, FL, 33907 (LEE)", date_range: "(08/01/2012-Current)" },
                { id: 3, name: "7460 LAKE BREEZE DR APT 308, FORT MYERS, FL, 33907 (LEE)", date_range: "(06/01/2014-04/24/2021)" },
                { id: 4, name: "16552 WELLINGTON LAKES CIR, FORT MYERS, FL, 33908 (LEE)", date_range: "(02/24/2005-09/18/2012)" },
                { id: 5, name: "1794 PINE GLADE CIR, FORT MYERS, FL, 33907 (LEE)", date_range: "(08/30/2001-03/08/2005)" },
                { id: 6, name: "1285 CARL D SILVER PKWY, FREDERICKSBURG, VA, 22401 (FREDERICKSBURG CITY)", date_range: "(07/01/2001-08/30/2001)" },
                { id: 7, name: "3716 CARLYLE CT, FREDERICKSBURG, VA, 22408 (SPOTSYLVANIA)", date_range: "(05/12/1998-09/15/2001)" },
                { id: 8, name: "2500 WISCONSIN AVE NW APT 231, WASHINGTON, DC, 20007 (DISTRICT OF COLUMBIA)", date_range: "(02/01/1993-12/31/1993)" },
                { id: 9, name: "THE TOWERS, WASHINGTON, DC, 20016 (DISTRICT OF COLUMBIA)", date_range: "(07/01/1992)" },
                { id: 10, name: "503 MONTPELIER DR, STAFFORD, VA, 22556 (STAFFORD)", date_range: "(04/01/1992-05/12/1998)" }
            ]
        },
        properties () {
            return [
                {}
            ]
        },
        aircrafts () {
            return []
        }
        */
    },
    data () {
        return {
            entity: "owner",
            entityName: "Owner:::Owners",
            relation_ids: ["account_owner", "heir"]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#owner-editor {
    section + section,
    .columns + section {
        margin-top: $unit-8;
    }
    .accordion:first-child,
    .accordion + .accordion,
    .variations-display + h6 {
        margin-top: $unit-4;
    }
    section {
        padding: 0 $unit-4;
    }
    label {
        display: inline-block;

        &:not(.disabled-field) {
            font-weight: bold;
            margin-right: $unit-2;
        }
    }
    .owner-aliases {
        display: flex;
        align-items: center;
        margin-bottom: $unit-8;

        div:not(:last-child) {
            flex: 1 0 auto;
            margin-left: $unit-4;
            margin-right: $unit-4;
        }
        h2, div:last-child {
            flex: 0 0 auto;
        }
        h2 {
            margin-bottom: 0;
        }
        @media (max-width: $size-sm) {
            display: block;
            div:not(:last-child) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: $unit-4;
            }
            h2 {
                margin-bottom: $unit-4;
            }
        }
    }
    aside.internal-fields {
        $padding: (2 * $layout-spacing) + $layout-spacing-lg;
        position: sticky;
        top: 70px;
        margin-left: (0 - $padding);
        padding: $layout-spacing-lg $layout-spacing-lg $layout-spacing-lg $padding;
        background-color: $gray-color-light;

        & > .title-proxy {
            font-size: 0; //1.4rem;
            height: 0;
            line-height: 0;
            margin: 0;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
        }
        &.stuck {
            & > .title-proxy {
                font-size: 1.4rem;
                height: 1.8rem;
                line-height: 1.8rem;
                margin: 0;
                overflow: hidden;
                transition: all 0.1s ease-in-out;
            }
        }
        @media (max-width: $size-sm) {
            position: relative;
            top: auto;
            margin-left: 0;
            margin-bottom: $unit-8;
            padding: $layout-spacing $layout-spacing-lg $layout-spacing $layout-spacing-lg;
        }
    }
}
</style>
