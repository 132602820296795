<template>
    <section>
        <h6>
            {{ translate("Family Heir Chart") }}
            <default-button
                flavor="link"
                size="sm"
                class="ml-2"
                @click.prevent.stop="add_row"
            >
                <open-icon glyph="plus" />
            </default-button>
        </h6>

        <data-grid
            :columns="columns"
            :rows="rows"
        >
            <template v-slot:thead="{ column, field }">
                {{ column.label }}
                <default-button
                    v-if="(field === 'name') && !shell_record"
                    flavor="link"
                    @click.prevent.stop="add_row"
                >
                    <open-icon glyph="plus-circle" />
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <form-input
                    v-if="field === 'name'"
                    :id="`${field}-${row.id}`"
                    :name="`${field}-${row.id}`"
                    v-model="row.name"
                    :required="false"
                    @input="save(row, $event, field)"
                />
                <form-dropdown
                    v-else-if="field === 'relationship'"
                    :id="`${field}-${row.id}`"
                    :name="`${field}-${row.id}`"
                    v-model="row.relationship"
                    :options="constant_options('heir_relationships')"
                    :required="false"
                    @input="save(row, $event, field)"
                />
                <form-dropdown
                    v-else-if="field === 'status'"
                    :id="`${field}-${row.id}`"
                    :name="`${field}-${row.id}`"
                    v-model="row.status"
                    :options="constant_options('heir_statuses')"
                    :required="false"
                    @input="save(row, $event, field)"
                />
                <div v-else-if="field === '_buttons'">
                    <div
                        v-if="!row.id"
                        class="columns"
                    >
                        <div class="column col-6 col-sm-12">
                            <default-button
                                color="error"
                                size="sm"
                                :block="true"
                                @click.prevent.stop="shell_record = null"
                            >
                                <open-icon glyph="times" />
                            </default-button>
                        </div>
                        <div class="column col-6 col-sm-12">
                            <default-button
                                color="success"
                                size="sm"
                                :block="true"
                                @click.prevent.stop="create"
                            >
                                <open-icon glyph="check" />
                            </default-button>
                        </div>
                    </div>
                    <default-button
                        v-else
                        flavor="link"
                        color="error"
                        size="xs"
                        :block="true"
                        class="mt-2"
                        @click.prevent.stop="confirm_delete(row)"
                    >
                        {{ translate("Delete") }}
                    </default-button>
                </div>
            </template>
        </data-grid>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </section>
</template>

<script>
import { mapGetters } from "vuex"
import { handles_saving, confirms, misc_utilities } from "@/nibnut/mixins"

import {
    FormInput,
    FormDropdown,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    DataGrid
} from "@/custom/components"

export default {
    name: "Heirs",
    mixins: [handles_saving, confirms, misc_utilities],
    components: {
        FormInput,
        FormDropdown,
        DefaultButton,
        OpenIcon,
        DataGrid
    },
    methods: {
        save (heir, value, field) {
            if(heir.id) this.save_field_for_record_id(this.entity, heir.id, value, field)
        },
        add_row () {
            this.$store.dispatch("FETCH_RECORD_SHELL", { entity: this.entity }).then(record => {
                this.shell_record = record
                this.shell_record.owner_id = this.ownerId
                // this.shell_record.category = this.category
            }).catch(this.receive_error)
        },
        create () {
            if(this.shell_record) {
                const data = { ...this.shell_record }
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data
                }).then(() => {
                    this.shell_record = null
                }).catch(this.receive_error)
            }
        },
        confirm_delete (heir) {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Delete Heir \"{name}\"", { name: heir.name }),
                    message: this.translate("Do you really want to delete this heir? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Delete"),
                    record: heir
                },
                "delete-row"
            )
        },
        confirmed () {
            if(this.confirming === "delete-row") {
                if(this.confirmation_props.record) {
                    this.$store.dispatch(
                        "RECORD_DELETE",
                        {
                            entity: this.entity,
                            id: this.confirmation_props.record.id
                        }
                    ).then(() => {
                        this.done_confirming()
                    }).catch(this.receive_error)
                }
            } else this.done_confirming()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        rows () {
            if(!this.ownerId) return []
            const rows = this.entity_records("heir").filter(heir => {
                return heir.owner_id === this.ownerId
            })
            if(this.shell_record) rows.push(this.shell_record)
            return rows
        }
    },
    props: {
        ownerId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "heir",
            columns: {
                name: { label: "Name" },
                relationship: { label: "Relationship" },
                status: { label: "Status" },
                _buttons: { label: " " }
            },
            shell_record: null
        }
    }
}
</script>
